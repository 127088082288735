import { useContext, useState } from "react";
import { useFormik } from "formik";
// import { useContext } from "react";
import { CircularProgress } from "@material-ui/core";

import { StyledForm, Background } from "./styles";
import ModalContainer from "./../ModalContainer";
import Input from "../../Input";
import Button from "../../Button";
// import FirstAccessModal from "../FirstAccessModal";
import { AuthContext } from "../../../contexts/auth";
import api from "../../../services/api";

// import { setStorage } from "../../../utils/localStorageHandler";
// import { api } from "../../../services/api";
// import { LoginResponse } from "../../../interface/login.interface";
// import { setStorage } from "../../../helpers/localStorageHandler";

interface modalProps {
  modalDismiss: () => void;
  idAccount: any;
  idQuestion: any
}
/**
 * @deprecated must be refract
 */
const ReportQuestion = ({ modalDismiss, idAccount, idQuestion }: modalProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const { isUserLogged } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      feedback: "",
    },
    onSubmit: (values) => {
      handleReport(values.feedback);
    },
  });

  const handleReport = async ({ feedback }: any) => {
    setIsLoading(false);

    if (isUserLogged) {
      try {

        const body = {
          id_question: idQuestion,
          id_account: idAccount,
          feedback: formik.values.feedback
        };

        console.log(body);

        await api.post(`api/student/report/question`, body);

        alert('Obrigado! Nossos revisores irão analisar o seu feedback e realizar as devidas correções');
        handleCloseModal();

      } catch (err) {
        alert('Desculpe. Não foi possível registrar o seu reporte, tente novamente mais tarde.');
      }
    } else {
      alert('Desculpe. É necessário realizar o seu cadastro na revise para poder reportar uma questão.')
    };
  };

  const handleCloseModal = (() => {
    modalDismiss();
  })

  return (
    <>
      <Background>

        <ModalContainer
          modalDismiss={handleCloseModal}
          title="Reportar questão"
          subtitle="Insira sua análise no campo abaixo, a equipe da revise irá analisar cuidadosamente."
        >
          <StyledForm onSubmit={formik.handleSubmit} autoComplete="off">
            <Input
              name="feedback"
              type="name"
              placeholder="Insira seu comentário *"
              onChange={formik.handleChange}
              value={formik.values.feedback}
              color="gray"
            />

            {isLoading ? (
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <Button isSecondary={false} type="submit">
                  REPORTAR
                  </Button>
              </>
            )}
          </StyledForm>
        </ModalContainer>
      </Background>
    </>
  );
};

export default ReportQuestion;
