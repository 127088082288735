import styled from "styled-components";

export const ContentCorrection = styled.div<{ bgColor: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  padding: 24px;
  background-color: ${(props) => props.bgColor};
`;

export const AlternativesImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: 800px) {
    gap: 8px;
  }
`;

export const Image = styled.img`
  max-width: 700px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1vw;
  width: 100%;
  border-radius: 8px;
  background: #ffff;
`;

export const ButtonInternal = styled.button<{
  isSelected: boolean;
}>`
  display: flex;
  padding: 6px 12px;
  border-style: none;
  text-align: justify;
  color: #000000;
  height: fit-content;
  border-radius: 6px;
  background-color: ${(props) => (props.isSelected ? "#547cff" : "unset")};
`;

export const QuestionRow = styled.div`
  display: flex;
  flex-direction: row;
`;
