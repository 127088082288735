import notebookImg from '../../assets/imgs/notebook.png'
import peopleImg from '../../assets/imgs/people.png'

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { questionsData } from './utils';

import * as S from './styles'
import { Card } from './Card';

export const FaqQuestions = () => {
  return (
    <S.Container>
      <S.MasonryContainer>
        <div>
          <S.FaqItem >
            <S.ImgContainer>
              <QuestionMarkIcon color='primary' fontSize='large' />
            </S.ImgContainer>
            Dúvidas frequentes
          </S.FaqItem>

          <S.ImageItem img={notebookImg} height='520px' />
        </div>


        {questionsData.map((question) => (
          <S.Item height={question.height} key={question.id}>
            <Card
              key={question.id}
              frontContent={question.title}
              backContent={question.content}
              color={question.color}
              textColor={question.textColor}
            />
          </S.Item>
        ))}

        <S.ImageItem img={peopleImg} height='460px' />

      </S.MasonryContainer>
    </S.Container >
  );
}