

import * as S from './styles'
import Slider from 'react-slick'

import { Feedback } from '../../components/Feedback'
import { feedbackData } from './helper';

export const FeedbackPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  return (
    <S.Container>
      <S.Title>Depoimentos & Feedbacks</S.Title>
      <Slider {...settings}>
        {feedbackData.map((feed) =>
          <Feedback key={feed.message} data={feed} />
        )}
      </Slider>
    </S.Container>)
}