import styled, { css } from "styled-components";

export const Container = styled.nav`
  min-width: 237px;
  max-width: 280px;
  height: 100%;
  position: relative;
  padding: 20px 10px;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  transition: width 300ms;
`;

export const CloseDrawer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding-bottom: 20px;

  :hover {
    cursor: pointer;
  }
`;

export const RegisterInfo = styled.div`
  max-width: 80%;
  margin: auto;
  padding: 20px 0;
`;

export const MenuItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonLink = styled.button<{ isActive?: boolean }>`
  border: none;
  background-color: transparent;
  display: flex;
  gap: 14px;
  padding: 10px 5px;

  ${({ isActive }) =>
    isActive &&
    css`
      background: #3a66fb;
      border-radius: 8px;
      p {
        color: white;
      }
    `}
`;

export const ImgHideMobile = styled.img`
  @media (max-width: 700px) {
    display: none;
  }
`;
