/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from '../../components/Button';

import {
  Container,
  ContainerLoading,
  Image,
  Row,
  ButtonInternal,
  ContentCorrectCorrection,
  ContentCorrection,
  ButtonEditQuestion
} from './styles'

//components
import FilterQuestions from '../../components/FilterQuestions';
import api from '../../services/api';
import { questionStudied } from './interface';
import { AuthContext } from '../../contexts/auth';
import { useLocation } from "react-router-dom-v5-compat";
import { revisionsProps } from '../Revisions/interface';
import ReportQuestion from '../../components/modal/ReportQuestion';
// import api from '../../services/api';

const Revision: React.FC = () => {


  const { typeUser, isUserLogged, isFeedbackQuestion, user } = useContext(AuthContext);
  const ITEMS_PER_PAGE = 100;

  const location = useLocation();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const [isLoading, setIsLoading] = useState(false);
  const [isReportQuestion, setIsReportQuestion] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [errorQuestion, setErrorQuestion] = useState<any[]>([]);
  const [correctQuestion, setCorrectQuestion] = useState<any[]>([]);
  const [optionSelected, setOptionSelected] = useState<any[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [idRevision, setIdRevision] = useState(0);
  const [currentIssue, setCurrentIssue] = useState('');

  const idAccount = user.id_user;
  const time = ['30 min', '1h', '1h30', '2h', '2h30', '3h', '3h30', '4h', '4h30', '5h'];

  useEffect(() => {    
    const objectRevision = location.state as revisionsProps;
    handleGetSubjectsStudied(objectRevision);
  }, [location]);

  const handleGetSubjectsStudied = async (objectRevision: revisionsProps) => {
    try {
      setIsLoading(true);
      let themesList: string[] = [];

      const questionsSubject = await api.get(`/api/student/revision/${objectRevision.id}`);
      setOptionSelected(Array(questionsSubject.data.length).fill({ option: '', isResponse: false }));
      setErrorQuestion(Array(questionsSubject.data.length).fill(''));
      setCorrectQuestion(Array(questionsSubject.data.length).fill(''));
      setPageData(questionsSubject.data);

    } catch (error) {
    }
    setIsLoading(false);
  }

  const handleResponseQuestion = (async (questionID: any, option: string, index: number) => {
    //add request get details response question.

    if (optionSelected[index].isResponse) {
      alert('Não é possível responder essa questão novamente.');
      return;
    }

    // const feedbackListResponses = 

    if (isUserLogged) {

      try {

        const body = {
          id_question: questionID,
          id_account: user.id_user,
          feedback: option
        };

        await api.post(`api/responseQuestion`, body);

        const listResponseCorrect = correctQuestion;
        listResponseCorrect[index] = questionID;

        setCorrectQuestion(listResponseCorrect);

      } catch (err) {

        const listResponseError = errorQuestion;
        listResponseError[index] = questionID;

        setErrorQuestion(listResponseError);

      } finally {
        const listItems = optionSelected;
        listItems[index] = {
          option: listItems[index].option,
          isResponse: true,
        };

        setOptionSelected(listItems);
        setIsUpdate(!isUpdate);
      }
    } else {
      alert('Desculpe. É necessário realizar o seu cadastro na revise para responder as questões.')
    }

  })

  const handleSelectOption = (item: string, index: number) => {

    console.log(optionSelected);

    if (optionSelected[index].isResponse) {
      alert('Não é possível responder essa questão novamente.');
      return;
    }

    const listItems = optionSelected;
    listItems[index] = {
      option: item,
      isResponse: false,
    };

    setOptionSelected(listItems);
    setIsUpdate(!isUpdate);
  }


  const handleReport = (questionID: string) => {
    setCurrentIssue(questionID);
    setIsReportQuestion(true);
  };


  return (
    <>
      <Container>
        {isLoading ? (
          <ContainerLoading>
            <CircularProgress />
          </ContainerLoading>
        ) : (
          <>
            {pageData.length > 0 ? (
              <div className="title">
                <h3>Revisão</h3>
              </div>

            ) : (
              <div className='themes'>
                <div className='themesFailed'>
                  <p>
                    Carregando questões
                  </p>
                </div>
              </div>
            )}
          </>
        )}

        <>
          {pageData.map((question: any, i) => {

            return (
              <div className="listQuestions" key={question.id}>
                <div className="question">

                  {question.description_issue !== '' &&
                    <Image src={question.description_issue} alt="" />
                  }

                  {question.alternatives_image !== '' &&
                    <Image src={question.alternatives_image} alt="" />
                  }

                  {optionSelected[i]?.option === question.id + 'a' ? (
                    <>
                      <Row>
                        <ButtonInternal key={question.id + 'a'} onClick={(e) => handleSelectOption(question.id + 'a', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '} {question.alternative_a}</ButtonInternal>
                        <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'A', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                      </Row>
                    </>
                  ) :
                    <ButtonInternal key={question.id + 'a'} onClick={(e) => handleSelectOption(question.id + 'a', i)}>{' '} {question.alternative_a}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'b' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'b'} onClick={(e) => handleSelectOption(question.id + 'b', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '} {question.alternative_b}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'B', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) :
                    <ButtonInternal key={question.id + 'b'} onClick={(e) => handleSelectOption(question.id + 'b', i)}>{' '} {question.alternative_b}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'c' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'c'} onClick={(e) => handleSelectOption(question.id + 'c', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '} {question.alternative_c}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'C', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) :
                    <ButtonInternal key={question.id + 'c'} onClick={(e) => handleSelectOption(question.id + 'c', i)}>{' '} {question.alternative_c}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'd' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'd'} onClick={(e) => handleSelectOption(question.id + 'd', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '}{question.alternative_d}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'D', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) :
                    <ButtonInternal key={question.id + 'd'} onClick={(e) => handleSelectOption(question.id + 'd', i)}>{' '}{question.alternative_d}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'e' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'e'} onClick={(e) => handleSelectOption(question.id + 'e', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '}{question.alternative_e}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'E', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) : (
                    <ButtonInternal key={question.id + 'e'} onClick={(e) => handleSelectOption(question.id + 'e', i)}>{' '}{question.alternative_e}</ButtonInternal>
                  )}

                  {errorQuestion[i] === question.id && (
                    <>
                      <ContentCorrection>

                        <p>A alternativa correta é a letra {question.feedback}</p>

                        {isFeedbackQuestion && (
                          <>
                            <p>{question.comment_correction}</p>
                            {question.image !== '' &&
                              <Image src={question.image} alt="" />
                            }
                            <Button isSecondary={true} type="submit" onClick={() => { handleReport(question.id) }}>
                              REPORTAR ERRO
                            </Button>
                          </>
                        )}

                        {!isFeedbackQuestion && (
                          <>
                            <p>Assine um plano da revise para ter acesso ao comentário detalhado da resolução das questões! Assim, você também irá contar com o nosso algoritmo de revisão espaçada para otimizar ao máximo o seu estudo ! </p>
                          </>
                        )}

                      </ContentCorrection>
                    </>
                  )}

                  {correctQuestion[i] === question.id && (
                    <>
                      <ContentCorrectCorrection>
                        <p>Resposta correta!</p>

                        {isFeedbackQuestion && (
                          <>
                            <p>{question.comment_correction}</p>
                            {question.image !== '' &&
                              <Image src={question.image} alt="" />
                            }
                            <Button isSecondary={true} type="submit" onClick={() => { handleReport(question.id) }}>
                              REPORTAR ERRO
                            </Button>
                          </>
                        )}

                        {!isFeedbackQuestion && (
                          <>
                            <p>Assine um plano da revise para ter acesso ao comentário detalhado da resolução das questões! Assim, você também irá contar com o nosso algoritmo de revisão espaçada para otimizar ao máximo o seu estudo !  </p>
                          </>
                        )}

                      </ContentCorrectCorrection>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </>
      </Container >


      {isReportQuestion && (
        <ReportQuestion
          modalDismiss={() => { setIsReportQuestion(false) }}
          idQuestion={currentIssue}
          idAccount={idAccount}
        />
      )}
    </>
  );
}

export default Revision;


