function maskPhone(value: string) {
  value = value.replace(/\D/g, "");  //Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/g, "($1)$2"); //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
  value = value.substr(0, 15);
  return value;
}

function maskCPF(value: string) {
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{3})(\d)/, "$1.$2")
  value = value.replace(/(\d{3})(\d)/, "$1.$2")
  value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return value;
}

function maskRG(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
  return value;
}

function maskPrice(value: number | string) {
  return Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

function numberMask(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{1})(\d)/, '($1$2) ')
    .replace(/(\d{3})(\d)/, '$1$2')
    .replace(/(\d{3})(\d)/, '$1$2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(\d{3})(\d)/, '$1$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

function maskCep(value: string) {
  value = value.replace(/\D/g, ""); // 1239856
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  return value;
}

function maskCurrency(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  return value;
}

function maskDate(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1/$2");
  value = value.replace(/(\d{2})(\d)/, "$1/$2");
  return value;
}


export { maskPhone, maskCPF, maskCep, maskCurrency, maskDate, maskPrice, numberMask, maskRG };
