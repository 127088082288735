import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  padding-bottom: 3vh;
  background-color: #eceff1;
`;

export const Content = styled.div`
  width: 100%;
  padding: 3vh 2vh;
  background-color: #eceff1;
  margin-bottom: 20px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    padding-left: 8px;
    padding-right: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 3vh;
  gap: 16px;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5vh;
`;