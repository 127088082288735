import styled from "styled-components";

interface ContainerInputProps {
  color: "white" | "gray";
}

/**
 * @deprecated use from @components/atoms instead
 */
export const ContainerInput = styled.input<ContainerInputProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 20px;
  background: ${(props) => (props.color === "white" ? "#FFF" : "#ECF0F2")};
  border-radius: 8px;
  border-style: none;
  font-family: inherit;
  &::placeholder {
    font-size: 12px;
    line-height: 16px;
    color: #78909d;
  }
`;
