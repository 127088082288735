import {
  TCorrectionFeedbackProps,
  TOptionButtonProps,
  TQuestionImageProps,
} from "./type";

import * as S from "./styles";
import { Button, Typography } from "../../components";

export const QuestionImage = ({ src }: TQuestionImageProps) => {
  if (!src) return null;

  return (
    <span>
      <S.Image src={src} alt="" />
    </span>
  );
};

export const OptionButton = ({
  option,
  questionId,
  isSelected,
  onSelect,
}: TOptionButtonProps) => {
  const key = questionId + option.toLowerCase();

  return (
    <S.ButtonInternal
      key={key}
      onClick={onSelect}
      isSelected={isSelected}
    >
      {option}
    </S.ButtonInternal>
  );
};

export const CorrectionFeedback = ({
  question,
  isFeedbackQuestion,
  isCorrect = false,
  handleReport,
}: TCorrectionFeedbackProps) => (
  <S.ContentCorrection bgColor={isCorrect ? "#8fcaa8" : "#f4aaaa"}>
    <Typography weight={600}>
      {isCorrect
        ? "Resposta correta!"
        : `A alternativa correta é a letra ${question.feedback}`}
    </Typography>

    {isFeedbackQuestion ? (
      <>
        <Typography weight={400}>{question.comment_correction}</Typography>

        {question.image && (
          <span>
            <S.Image src={question.image} />
          </span>
        )}

        <Button isSecondary onClick={() => handleReport(question.id)}>
          Reportar erro
        </Button>
      </>
    ) : (
      <Typography variant="small">
        Assine um plano da revise para ter acesso ao comentário detalhado da
        resolução das questões! Assim, você também irá contar com o nosso
        algoritmo de revisão espaçada para otimizar ao máximo o seu estudo!
      </Typography>
    )}
  </S.ContentCorrection>
);
