export enum EAvailableFields {
  COURSES = "courses",
  MATTERS = "matters",
  BANKS = "banks",
  YEARS = "years",
  DIFFICULTY = "difficulty",
  AMOUNT = "amount",
  TIME = "time",
}

export type TFilterFormValues = {
  courses: Array<string>;
  matters: Array<string>;
  banks: Array<string>;
  years: Array<string>;
  difficulty: Array<string>;
  amount: string;
  time: string;
};

export type TQuestionFilterProps = {
  fieldToShow: Array<EAvailableFields>;
  requiredFields?: Array<EAvailableFields>;
  isFlashCard?: boolean;
  onSubmit: (data: TFilterFormValues) => void;
  isLoadingFilter?: boolean
  showNotFound?: boolean;
};

export type TFilterOptions = {
  banks: Array<string>;
  courses: {
    [matter: string]: Array<string>
  };
  difficulties: Array<string>;
  years: Array<string>;
};
