import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CircularProgress } from "@material-ui/core";
import { Button, MultipleSelect, Typography } from "../../../components";

import api, { END_POINTS } from "../../../services/api";
import { amountOption, timeOption } from "./helper";

import {
  EAvailableFields,
  TFilterFormValues,
  TFilterOptions,
  TQuestionFilterProps,
} from "./types";
import * as S from "./styles";

export const QuestionFilter = ({
  fieldToShow,
  requiredFields,
  isFlashCard = false,
  showNotFound = false,
  isLoadingFilter,
  onSubmit,
}: TQuestionFilterProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<TFilterOptions>();
  const [matterOptions, setMatterOptions] = useState<Array<string>>([]);

  const initialValues: TFilterFormValues = {
    courses: [],
    matters: [],
    banks: [],
    years: [],
    difficulty: [],
    amount: "",
    time: "",
  };

  useEffect(() => {
    const handleQuestion = async () => {
      try {
        const filter = await api.get<TFilterOptions>(
          `${END_POINTS.filterQuestions}?flashcard=${isFlashCard}`
        );

        setFilterOptions(filter.data);
      } finally {
        setIsLoading(false);
      }
    };

    if (!filterOptions) handleQuestion();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (data) => onSubmit(data),
  });

  const requiredFieldsFilled = () => {
    if (requiredFields) {
      return requiredFields.every((field) => {
        const value = formik.values[field];
        return typeof value === "string"
          ? !!value.trim()
          : Array.isArray(value) && value.length > 0;
      });
    }
    return true;
  };

  const isRequired = (field: EAvailableFields) =>
    requiredFields?.includes(field) ? "*" : "";

  useEffect(() => {
    if (filterOptions?.courses) {
      let values = [];
      for (const key of formik.values.courses) {
        values.push(...filterOptions.courses[key]);
      }

      const uniqueValues = values.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      setMatterOptions(uniqueValues);
    }

    if (formik.values.courses.length === 0) {
      formik.setFieldValue(EAvailableFields.MATTERS, initialValues.matters);
    }
  }, [formik.values.courses]);

  const handleResetForm = () => {
    formik.resetForm();
  };

  return (
    <S.Container>
      {isLoading || !filterOptions ? (
        <S.Center>
          <CircularProgress />
        </S.Center>
      ) : (
        <S.Form onSubmit={formik.handleSubmit} noValidate>
          <S.Content>
            {fieldToShow.includes(EAvailableFields.COURSES) && (
              <MultipleSelect
                label={`Disciplina ${isRequired(EAvailableFields.COURSES)}`}
                name={EAvailableFields.COURSES}
                options={Object.keys(filterOptions?.courses ?? [])}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.MATTERS) && (
              <MultipleSelect
                label={`Assunto ${isRequired(EAvailableFields.MATTERS)}`}
                name={EAvailableFields.MATTERS}
                options={matterOptions}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.BANKS) && (
              <MultipleSelect
                label={`Banca ${isRequired(EAvailableFields.BANKS)}`}
                name={EAvailableFields.BANKS}
                options={filterOptions.banks}
                formik={formik}
                upperCase
              />
            )}

            {fieldToShow.includes(EAvailableFields.YEARS) && (
              <MultipleSelect
                label={`Ano ${isRequired(EAvailableFields.YEARS)}`}
                name={EAvailableFields.YEARS}
                options={filterOptions.years}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.DIFFICULTY) && (
              <MultipleSelect
                label={`Dificuldade ${isRequired(EAvailableFields.DIFFICULTY)}`}
                name={EAvailableFields.DIFFICULTY}
                options={filterOptions.difficulties}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.AMOUNT) && (
              <MultipleSelect
                label={`Quantidade de cards ${isRequired(
                  EAvailableFields.AMOUNT
                )}`}
                name={EAvailableFields.AMOUNT}
                options={amountOption}
                formik={formik}
                acceptOne
              />
            )}

            {fieldToShow.includes(EAvailableFields.TIME) && (
              <MultipleSelect
                label={`Tempo ${isRequired(EAvailableFields.TIME)}`}
                name={EAvailableFields.TIME}
                options={timeOption}
                formik={formik}
                acceptOne
              />
            )}
          </S.Content>

          {showNotFound && !!formik.submitCount && !isLoadingFilter && (
            <S.NotFoundContainer>
              <Typography color="red" weight={500}>
                Não foram encontradas questões para o filtro selecionado.
              </Typography>
              <Typography variant="small" color="red">
                Tente outra combinação.
              </Typography>
            </S.NotFoundContainer>
          )}

          <S.ButtonContainer>
            <Button
              size="small"
              isSecondary
              type="reset"
              onClick={handleResetForm}
            >
              Limpar filtro
            </Button>

            <Button
              type="submit"
              size="small"
              disabled={!requiredFieldsFilled() || isLoading}
              isLoading={isLoadingFilter}
            >
              Filtrar questões
            </Button>
          </S.ButtonContainer>
        </S.Form>
      )}
    </S.Container>
  );
};
