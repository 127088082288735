/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';

import { Container } from './styles'

//components
import BannerHome from '../../components/BannerHome';
import { AboutUs } from '../../components/AboutUs';
import { FaqQuestions } from '../FaqQuestions';
import { PlanValues } from '../PlanValues';

import { AuthContext } from '../../contexts/auth'
import { useNavigate } from "react-router-dom-v5-compat";
import { FeedbackPage } from '../FeedbackPage';
import { ERoutesPath } from '../../routes';

const Home: React.FC = () => {
  const { isUserLogged, typeUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLogged && typeUser === '10') {
      navigate(ERoutesPath.INICIO_PROFISSIONAL);
    }
  }, [typeUser, isUserLogged])

  return (
    <Container>
      <BannerHome />
      <AboutUs />
      <FeedbackPage />
      <FaqQuestions />
      <PlanValues />
    </Container>
  );
}

export default Home;