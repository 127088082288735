import { InputHTMLAttributes } from 'react';
import { ContainerInput } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder: string;
  color?: 'white' | 'gray';
  numberOfLines?: number;
}

/**
 * @deprecated use from @components/atoms instead
 */
const Input = ({ name, placeholder, color = 'white', numberOfLines, ...rest }: InputProps) => {
  return (
    <ContainerInput
      className='Input'
      name={name}
      id={name}
      placeholder={placeholder}
      color={color}
      autoComplete="off"
      type='hidden'
      {...rest}
    />
  );
};

export default Input;