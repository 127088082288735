/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Collapse } from 'react-collapse';

import Button from '../../components/Button';

import {
  Container,
  ContainerLoading,
  ContentFilter,
  RowButton,
  Image,
  Row,
  ButtonInternal,
  ContentCorrectCorrection,
  ContentCorrection,
  ButtonEditQuestion
} from './styles'

//components
import FilterQuestions from '../../components/FilterQuestions';
import api from '../../services/api';
import { getStorage } from '../../utils/localStorageHandler';
import { FormControl, Pagination } from '@material-ui/core';
import { questionStudied } from './interface';
import { AuthContext } from '../../contexts/auth';
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { revisionsProps } from '../Revisions/interface';
import ReportQuestion from '../../components/modal/ReportQuestion';
import { QuestionContext, TQuestionContext } from '../../contexts/question';
import { ERoutesPath } from '../../routes';
// import api from '../../services/api';

const RevisionGeneral: React.FC = () => {

  const { typeUser, isUserLogged, isFeedbackQuestion, user } = useContext(AuthContext);
  const { setSelectedQuestion } = useContext<TQuestionContext>(QuestionContext);
  const navigate = useNavigate();
  const ITEMS_PER_PAGE = 100;

  const location = useLocation();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isReportQuestion, setIsReportQuestion] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [selectedArea, setSelectedArea] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string[]>([]);
  const [themes, setThemes] = useState<string[]>([]);
  const [matters, setMatters] = useState<string[]>([]);
  const [selectedMatters, setSelectedMatters] = useState<string[]>([]);
  const [errorQuestion, setErrorQuestion] = useState<any[]>([]);
  const [correctQuestion, setCorrectQuestion] = useState<any[]>([]);
  const [optionSelected, setOptionSelected] = useState<any[]>([]);
  const [offset, setOffset] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [week, setWeek] = useState('');
  const [currentIssue, setCurrentIssue] = useState('');

  const idAccount = user.id_user;
  const time = ['30 min', '1h', '1h30', '2h', '2h30', '3h', '3h30', '4h', '4h30', '5h'];

  useEffect(() => {
    // NOTE: VERIFY
    const objectRevision = location.state as revisionsProps;
    const getWeek = objectRevision.area.split(' ');
    if (getWeek) {
      setWeek(getWeek[2]);
    }

    handleGetSubjectsStudied(objectRevision);
  }, [location]);

  const handleGetSubjectsStudied = async (objectRevision: any) => {
    try {
      setIsLoading(true);
      let themesList: string[] = [];
      await api.get(`/api/student/revision/${objectRevision.id}`);
      const questionsSubject = await api.get(`/api/student/subject/studied/${idAccount}`);

      setAllData(questionsSubject.data);

      questionsSubject.data.forEach((questionParam: questionStudied) => {
        if (themesList.indexOf(questionParam.course) === -1 && questionParam.course !== '-') {
          themesList.push(questionParam.course);
        }
      });
      setThemes(themesList);

    } catch (error) {
    }
    setIsLoading(false);
  }

  useEffect(() => {

    let mattersList: string[] = [];

    if (selectedArea.length > 0) {
      let filterQuestions: questionStudied[] = [];

      allData.filter((question: questionStudied) => {
        selectedArea.forEach((value) => {
          if (question.course.toLowerCase().indexOf(value.toLowerCase()) !== -1 && question.course !== '-') {
            filterQuestions.push(question);
          }
        })
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea]);

  const handleChange = (event: SelectChangeEvent<string[]>, param: string) => {
    switch (param) {
      case 'area':
        setSelectedArea(event.target.value as string[]);
        break;
      case 'time':
        setSelectedTime(event.target.value as string[]);
        break;
      case 'matters':
        setSelectedMatters(event.target.value as string[]);
        break;
    }
  };

  const handleGenerateQuestions = async () => {

    setPageData([]);
    setOptionSelected([]);
    setCorrectQuestion([]);
    setErrorQuestion([]);

    if (selectedArea.length === 0 || selectedTime.length === 0) {
      alert('Desculpe. Todos os filtros precisam estar preenchidos.');
      return;
    }

    const objectFilter = {
      areas: selectedArea,
      time: selectedTime,
      idAccount: idAccount,
      week: week
    }

    try {
      const getQuestions = await api.post(`/api/student/revision/week`, objectFilter);

      console.table(getQuestions.data);

      setPageData(getQuestions.data);
      setOptionSelected(Array(getQuestions.data.length).fill({ option: '', isResponse: false }));
      setErrorQuestion(Array(getQuestions.data.length).fill(''));
      setCorrectQuestion(Array(getQuestions.data.length).fill(''));

    } catch (err) {

    }
  };

  const handleResponseQuestion = (async (questionID: any, option: string, index: number) => {
    //add request get details response question.

    if (optionSelected[index].isResponse) {
      alert('Não é possível responder essa questão novamente.');
      return;
    }

    // const feedbackListResponses = 

    if (isUserLogged) {

      try {

        const body = {
          id_question: questionID,
          id_account: user.id_user,
          feedback: option
        };

        await api.post(`api/responseQuestion`, body);

        const listResponseCorrect = correctQuestion;
        listResponseCorrect[index] = questionID;

        setCorrectQuestion(listResponseCorrect);

      } catch (err) {

        const listResponseError = errorQuestion;
        listResponseError[index] = questionID;

        setErrorQuestion(listResponseError);

      } finally {
        const listItems = optionSelected;
        listItems[index] = {
          option: listItems[index].option,
          isResponse: true,
        };

        setOptionSelected(listItems);
        setIsUpdate(!isUpdate);
      }
    } else {
      alert('Desculpe. É necessário realizar o seu cadastro na revise para responder as questões.')
    }

  })

  const handleSelectOption = (item: string, index: number) => {

    if (optionSelected[index].isResponse) {
      alert('Não é possível responder essa questão novamente.');
      return;
    }

    const listItems = optionSelected;
    listItems[index] = {
      option: item,
      isResponse: false,
    };

    setOptionSelected(listItems);
    setIsUpdate(!isUpdate);
  }

  const handleReport = (questionID: string) => {
    setCurrentIssue(questionID);
    setIsReportQuestion(true);
  };


  return (
    <>
      <Container>
        {isLoading ? (
          <ContainerLoading>
            <CircularProgress />
          </ContainerLoading>
        ) : (
          <>
            <div className="question">
              <h3>Selecionar questões</h3>
            </div>

            <ContentFilter >

              <FormControl className='form'>
                <InputLabel id="demo-mutiple-checkbox-label">Área(s)</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={selectedArea}
                  multiple
                  onChange={(event) => { handleChange(event, 'area') }}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  MenuProps={MenuProps}
                >
                  {themes.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedArea.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className='form'>
                <InputLabel id="demo-mutiple-checkbox-label">Tempo</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={selectedTime}
                  onChange={(event) => { handleChange(event, 'time') }}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[])}
                  MenuProps={MenuProps}
                >
                  {time.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedTime.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="filterList">
                <Button isSecondary size="medium" onClick={handleGenerateQuestions}>
                  Buscar questões
                </Button>
              </div>
            </ContentFilter>
          </>
        )}

        <>
          {pageData.map((question: any, i) => {

            return (
              <div className="listQuestions">
                <div className="question">

                  {typeUser === '10' && (
                    <RowButton className='editQuestion'>
                      <ButtonEditQuestion onClick={() => {
                        setSelectedQuestion(question);
                        navigate(ERoutesPath.ADMINISTRAR_QUESTOES);
                      }}>
                        Editar questão
                      </ButtonEditQuestion>
                    </RowButton>
                  )}

                  {question.description_issue !== '' &&
                    <Image src={question.description_issue} alt="" />
                  }

                  {question.alternatives_image !== '' &&
                    <Image src={question.alternatives_image} alt="" />
                  }

                  {optionSelected[i]?.option === question.id + 'a' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'a'} onClick={(e) => handleSelectOption(question.id + 'a', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '}{question.alternative_a}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'A', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) :
                    <ButtonInternal key={question.id + 'a'} onClick={(e) => handleSelectOption(question.id + 'a', i)}>{' '}{question.alternative_a}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'b' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'b'} onClick={(e) => handleSelectOption(question.id + 'b', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '}{question.alternative_b}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'B', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) :
                    <ButtonInternal key={question.id + 'b'} onClick={(e) => handleSelectOption(question.id + 'b', i)}>{' '}{question.alternative_b}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'c' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'c'} onClick={(e) => handleSelectOption(question.id + 'c', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '}{question.alternative_c}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'C', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) :
                    <ButtonInternal key={question.id + 'c'} onClick={(e) => handleSelectOption(question.id + 'c', i)}>{' '}{question.alternative_c}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'd' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'd'} onClick={(e) => handleSelectOption(question.id + 'd', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '} {question.alternative_d}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'D', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) :
                    <ButtonInternal key={question.id + 'd'} onClick={(e) => handleSelectOption(question.id + 'd', i)}>{' '} {question.alternative_d}</ButtonInternal>
                  }

                  {optionSelected[i]?.option === question.id + 'e' ? (
                    <Row>
                      <ButtonInternal key={question.id + 'e'} onClick={(e) => handleSelectOption(question.id + 'e', i)} style={{ backgroundColor: '#547CFF', borderRadius: 6 }}>{' '} {question.alternative_e}</ButtonInternal>
                      <ButtonInternal onClick={(e) => handleResponseQuestion(question.id, 'E', i)} style={{ backgroundColor: 'rgba(0, 184, 192, 0.5)', borderRadius: 6 }}>Confirmar</ButtonInternal>
                    </Row>
                  ) : (
                    <ButtonInternal key={question.id + 'e'} onClick={(e) => handleSelectOption(question.id + 'e', i)}>{' '} {question.alternative_e}</ButtonInternal>
                  )}

                  {errorQuestion[i] === question.id && (
                    <>
                      <ContentCorrection>

                        <p>A alternativa correta é a letra {question.feedback}</p>

                        {isFeedbackQuestion && (
                          <>
                            <p>{question.comment_correction}</p>
                            {question.image !== '' &&
                              <Image src={question.image} alt="" />
                            }
                            <Button isSecondary={true} type="submit" onClick={() => { handleReport(question.id) }}>
                              REPORTAR ERRO
                            </Button>
                          </>
                        )}

                        {!isFeedbackQuestion && (
                          <>
                            <p>Assine um plano da revise para ter acesso ao comentário detalhado da resolução das questões! Assim, você também irá contar com o nosso algoritmo de revisão espaçada para otimizar ao máximo o seu estudo ! </p>
                          </>
                        )}

                      </ContentCorrection>
                    </>
                  )}

                  {correctQuestion[i] === question.id && (
                    <>
                      <ContentCorrectCorrection>
                        <p>Resposta correta!</p>

                        {isFeedbackQuestion && (
                          <>
                            <p>{question.comment_correction}</p>
                            {question.image !== '' &&
                              <Image src={question.image} alt="" />
                            }
                            <Button isSecondary={true} type="submit" onClick={() => { handleReport(question.id) }}>
                              REPORTAR ERRO
                            </Button>
                          </>
                        )}

                        {!isFeedbackQuestion && (
                          <>
                            <p>Assine um plano da revise para ter acesso ao comentário detalhado da resolução das questões! Assim, você também irá contar com o nosso algoritmo de revisão espaçada para otimizar ao máximo o seu estudo !  </p>
                          </>
                        )}

                      </ContentCorrectCorrection>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </>
        {/* 
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={allData.length}
          offset={offset}
          setOffset={setOffset}
        /> */}
      </Container >

      {isReportQuestion && (
        <ReportQuestion
          modalDismiss={() => { setIsReportQuestion(false) }}
          idQuestion={currentIssue}
          idAccount={idAccount}
        />
      )}
    </>
  );
}

export default RevisionGeneral;


