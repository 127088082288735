import styled, { css } from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;

  padding: 20px;
`;

export const Card = styled.div<{ isTrue?: boolean }>`
  border-radius: 50px;
  min-width: 300px;
  max-width: 500px;
  min-height: 300px;

  padding: 25px;

  background-color: ${(props) => {
    if (props.isTrue === true) {
      return "#7ED956";
    } else if (props.isTrue === false) {
      return "#C54B47";
    } else {
      return "#FFC440";
    }
  }};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

export const Button = styled.button<{ color: string }>`
  display: flex;
  min-width: 180px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 40px;
  padding: 20px;

  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const ImgCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #000000;

  position: relative;
  top: -50px;

  z-index: 1;
`;

export const CenterCounter = styled.div`
  display: flex;
  justify-content: center;
  height: 0;
`;
