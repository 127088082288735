import {
  Container,
  TextWrapper,
  Title,
  Subtitle,
  // DataContainer,
  // DataValue,
  // ButtonContainer,
} from "./styles";

interface ListItemProps {
  title: string;
  subtitle: string;
  imgSrc?: string;
  typeAction: 'client' | 'balances';
  onPress: (client: any) => void;
  object: any;
}

const ListItem = ({
  imgSrc,
  title,
  subtitle,
  typeAction,
  onPress,
  object
}: ListItemProps) => {

  return (
    <Container className="">
      {imgSrc !== '' && (
        <img style={{ width: 45, height: 45 }} src={imgSrc} alt="" />
      )}
      <TextWrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextWrapper>

      {object.paid !== 0 ? (
        <>
          <button style={{ backgroundColor: '#F18A8A', color: 'white' }} onClick={() => onPress(object)}>Desabilitar</button>
          <button style={{ backgroundColor: 'white', color: '#029198' }}>Assinante</button>
        </>
      ) : (
        <>
          <button style={{ backgroundColor: '#029198', color: 'white' }} onClick={() => onPress(object)}>Habilitar</button>
          <button style={{ backgroundColor: 'white', color: '#F18A8A' }} onClick={() => onPress(object)}>Não assinante</button>
        </>
      )}
    </Container>
  );
};

export default ListItem;
