import { ERoutesPath } from "./routesPath";
import {
  Home,
  Questions,
  Professional,
  QuestionManagement,
  UpdateQuestion,
  SubjectsStudied,
  Clients,
  Revisions,
  Revision,
  RevisionGeneral,
  CorrectQuestions,
  FlashCardPage,
} from "../pages";

export const ROUTES = [
  { path: ERoutesPath.ROOT, element: Home },
  { path: ERoutesPath.QUESTOES, element: Questions },
  { path: ERoutesPath.INICIO_PROFISSIONAL, element: Professional },
  { path: ERoutesPath.ADMINISTRAR_QUESTOES, element: QuestionManagement },
  { path: ERoutesPath.ATUALIZAR_QUESTOES, element: UpdateQuestion },
  { path: ERoutesPath.ASSUNTOS_ESTUDADOS, element: SubjectsStudied },
  { path: ERoutesPath.USUARIOS, element: Clients },
  { path: ERoutesPath.REVISOES, element: Revisions },
  { path: ERoutesPath.REVISAO, element: Revision },
  { path: ERoutesPath.REVISAO_GERAL, element: RevisionGeneral },
  { path: ERoutesPath.CORRECAO, element: CorrectQuestions },

  { path: ERoutesPath.REVISE_RAPIDO, element: FlashCardPage },
];
