import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 36px;
  padding-right: 36px;

  .title {
    padding-top: 100px;
    margin-bottom: 24px;

    h3 {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 20px;
      color: #727272;

      @media (max-width: 800px) {
        font-size: 13px;
      }
    }
  }

  .question {
    display: grid;
    margin-top: 120px;
    width: 100%;
    margin-bottom: 70px;
    padding-bottom: 16px;

    @media (max-width: 800px) {
      margin-bottom: 0px;
      padding-left: 16px;
      padding-bottom: 16px;
    }

    h3 {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 20px;
      color: #727272;

      @media (max-width: 800px) {
        font-size: 13px;
      }
    }
  }

  .themes {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 60px;

    @media (max-width: 800px) {
      margin-top: 50px;
    }

    .themesSuccess {
      display: flex;
      width: 100%;
      background: #ffce76;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border-style: none;
      border-color: #727272;
      padding: 40px;

      @media (max-width: 800px) {
        width: 100%;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 18px;
        color: black;

        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
    }

    .themesFailed {
      display: flex;
      width: 100%;
      margin-top: 50px;
      background: #f18a8a;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border-style: none;
      border-color: #f18a8a;
      padding: 40px;

      @media (max-width: 800px) {
        width: 100%;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 18px;
        color: #ecf0f2;

        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
    }
  }

  .listQuestions {
    padding-top: 60px;
    width: 100%;
    margin-bottom: 16px;
    padding-left: 56px;
    padding-right: 56px;
    padding-bottom: 16px;
    background-color: white;

    @media (max-width: 800px) {
      padding-left: 6px;
      padding-right: 6px;
      width: 100%;
      padding-top: 120px;
    }

    h4 {
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 42px;
      width: 100%;
    }

    .question {
      border-top: 1px solid #dfdfdf;
      /* border-bottom: 1px solid #DFDFDF; */
      width: 100%;
      margin-top: 40px;
      padding: 40px 8px;
      @media (max-width: 800px) {
        padding: 4px 8px;
        margin-top: 0px;
      }

      p {
        font-family: "Nunito", sans-serif;
        font-weight: 600;
        font-size: 14px;
        color: black;
        padding-left: 8px;
        margin-bottom: 8px;
      }
    }
  }

  .editQuestion {
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const ContainerQuestions = styled.div`
  padding-bottom: 40px;
  width: 100%;

  .group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 10px;
  }
`;

export const RowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonEditQuestion = styled.button`
  display: flex;
  padding: 6px 12px;
  border-style: none;
  background: #c1cffe;
  border-radius: 4px;
`;

export const Button = styled.button`
  display: flex;
  padding: 6px 12px;
  border-style: none;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 3px;
  align-items: center;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 400px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 200px;
`;

export const Image = styled.img`
  padding-bottom: 80px;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const ContentFilter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 24px;

  @media (max-width: 800px) {
    padding-left: 8px;
    padding-right: 8px;
    grid-template-columns: 1fr;
  }
`;

export const TextInformation = styled.h3``;

export const ButtonInternal = styled.button`
  display: flex;
  padding: 6px 12px;
  border-style: none;
  text-align: start;
  color: #000000;
  height: fit-content;
`;

export const ContentCorrection = styled.div`
  width: 100%;
  background: #f4aaaa;
  margin-top: 24px;
  border-radius: 4px;
  padding: 24px;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
  }
`;

export const ContentCorrectCorrection = styled.div`
  width: 100%;
  background: #8fcaa8;
  margin-top: 24px;
  border-radius: 4px;
  padding: 24px;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
  }
`;
