import * as S from './styles'

type TFeedbackProps = {
  data: {
    message: string
    name?: string
  }
}

export const Feedback = ({ data }: TFeedbackProps) => (
  <S.Container>
    <S.Content>
      <S.Text>"{data.message}"</S.Text>
      {data.name && <S.TextRight>{data.name}</S.TextRight>}
    </S.Content>
  </S.Container>
)
