import styled from "styled-components";

export const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Button {
    width: 127px;
    height: 48px;
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

/**
 * @deprecated Update to use from component
 */
export const Link = styled.a`
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #6b8dfc;
`;

export const ContactText = styled.p`
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #78909d;
  margin-top: 56px;
`;

export const TextFeedbackOperation = styled.p`
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: red;
  margin-top: 16px;
`;
