import styled from 'styled-components';

import dropdownArrow from '../../assets/dropdownArrow.svg'

export const ContainerSelect = styled.select`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 16px;
  width: 100%;
  background: #ECF0F2;
  border-radius: 8px;
  border: none;
  text-align-last:left;
  font-family: inherit;;
  font-size: 12px;
  color: black;
  appearance: none;
  background-image: url(${dropdownArrow});
  background-repeat: no-repeat;
  background-position-x: calc(100% - 18px);
  background-position-y: 50%;
  margin-bottom: 16px;
`;

export const Option = styled.option`
  &[disabled]:first-child {
    display: none;
  }
`;