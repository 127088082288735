import { ModalContainer } from "../../../components";

import { PlanValues } from "../../../pages/PlanValues";
import { TPaymentModalProps } from "./types";

export const PaymentModal = ({ modalDismiss }: TPaymentModalProps) => {
  const handleCloseModal = () => {
    modalDismiss();
  };

  return (
    <ModalContainer
      modalDismiss={handleCloseModal}
      title="Realizar assinatura"
      subtitle="Assine o plano da revise e tenha acesso ao nosso algoritmo de revisão!"
    >
      <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
        <PlanValues isModal />
      </div>
    </ModalContainer>
  );
};
