
import { ThemeProvider } from 'styled-components';
import { HashRouter } from "react-router-dom-v5-compat";

import GlobalStyles from './styles/global';
import lightTheme from './styles/themes/light';

import AppProvider from './contexts';

import { AppRoutes } from '../src/routes';
import { FloatingWhatsapp } from './components/FloatingWhatsapp';


function App() {
  return (
    <>
      <HashRouter>
        <ThemeProvider theme={lightTheme}>
          <AppProvider>
            <GlobalStyles />
            <AppRoutes />
          </AppProvider>
        </ThemeProvider>
      </HashRouter>
      <FloatingWhatsapp />
    </>
  );
}

export default App;
