import Tooltip from "@mui/material/Tooltip";
import imageRight from "../../../assets/imageCorrect.png";
import imageWrong from "../../../assets/imageError.png";

import { TImageRightWrongProps } from "./types";
import * as S from "./styles";

export const ImageRightWrong = ({ isCorrect }: TImageRightWrongProps) => {
  return (
    <S.Container>
      {isCorrect !== undefined && (
        <Tooltip
          title={`Questão respondida ${
            isCorrect ? "corretamente" : "incorretamente"
          }!`}
          placement="left-end"
        >
          <img
            src={isCorrect ? imageRight : imageWrong}
            alt="Pergunta respondida"
            width={20}
            height={20}
          />
        </Tooltip>
      )}
    </S.Container>
  );
};
