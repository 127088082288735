/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom-v5-compat";
import { useFormik } from "formik";
import api from '../../../services/api';


import { CircularProgress } from '@material-ui/core';

import Input from "../../../components/Input";
import Select from "../../../components/Select";
import BackButton from '../../../components/BackButton';
import Button from "../../../components/Button";

import {
  Container,
  StyledForm,
  ContainerRow,
  ContaineForm,
  ContainerButton,
  FlexWrapper,
  Label,
} from './styles';
import { QuestionContext, TQuestionContext } from '../../../contexts/question';
import { AuthContext } from '../../../contexts/auth';
import { ERoutesPath } from '../../../routes';

/**
 * @deprecated Refracted - should use QuestionManagement
 */
export const UpdateQuestion: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { selectedQuestion } = useContext<TQuestionContext>(QuestionContext);
  const { typeUser } = useContext(AuthContext);

  const optionsWeight = [
    {
      value: 0,
      label: "Peso da questão",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    }, {
      value: 5,
      label: "5",
    }
  ];

  const optionsDifficulty = [
    {
      value: 0,
      label: "Dificuldade",
    },
    {
      value: 1,
      label: "Fácil",
    },
    {
      value: 2,
      label: "Médio",
    },
    {
      value: 3,
      label: "Difícil",
    }
  ];


  const optionsCorrectQuestion = [
    {
      value: 0,
      label: "Gabarito",
    },
    {
      value: 1,
      label: "A",
    },
    {
      value: 2,
      label: "B",
    },
    {
      value: 3,
      label: "C",
    },
    {
      value: 4,
      label: "D",
    },
    {
      value: 5,
      label: "E",
    }
  ]

  useEffect(() => {
    if (typeUser !== '10') {
      navigate(ERoutesPath.ROOT);
      return
    }

    if (!selectedQuestion) {
      navigate(ERoutesPath.QUESTOES);
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      course: selectedQuestion?.course,
      matter: selectedQuestion?.matter,
      bank: selectedQuestion?.bank,
      institution: selectedQuestion?.institution,
      year: selectedQuestion?.year,
      // office: "",
      // modality: "1",
      difficulty: Number(optionsDifficulty.findIndex((difficulty) => difficulty.label === selectedQuestion?.difficulty)),
      weight: Number(optionsWeight.findIndex((weight) => weight.label === selectedQuestion?.weight)),
      feedback: Number(optionsCorrectQuestion.findIndex((feedback) => feedback.label === selectedQuestion?.feedback)),
      comment_correction: selectedQuestion?.comment_correction,
      image: selectedQuestion?.image,
      description_issue: selectedQuestion?.description_issue,
      alternatives_image: selectedQuestion?.alternatives_image,
      alternative_a: selectedQuestion?.alternative_a,
      alternative_b: selectedQuestion?.alternative_b,
      alternative_c: selectedQuestion?.alternative_c,
      alternative_d: selectedQuestion?.alternative_d,
      alternative_e: selectedQuestion?.alternative_e,
    },
    onSubmit: (values) => {
      // handleSubmit(values.loginEmail, values.loginPassword);
    },
  });

  //Function - update question
  const handleUpdateQuestion = (async () => {

    if (formik.values.difficulty === 0 || formik.values.weight === 0 || formik.values.feedback === 0) {
      alert('É necessário realizar a selação dos campos dificuldade, peso da questão e gabarito.')
      return;
    } else {
      setIsLoading(true);

      try {

        //images
        //image
        //description_issue
        //alternatives_image
        const image = selectedQuestion?.image;
        const descriptionIssue = selectedQuestion?.description_issue;
        const alternativesImage = selectedQuestion?.alternatives_image;

        const objectSend = {
          id: selectedQuestion?.id,
          course: formik.values.course,
          matter: formik.values.matter,
          bank: formik.values.bank,
          institution: formik.values.institution,
          year: formik.values.year,
          office: "",
          level: "",
          // modality: formik.values.modality,
          difficulty: optionsDifficulty[formik.values.difficulty].label ? optionsDifficulty[formik.values.difficulty].label : formik.values.difficulty,
          weight: formik.values.weight,
          feedback: optionsCorrectQuestion[formik.values.feedback].label ? optionsCorrectQuestion[formik.values.feedback].label : formik.values.feedback,
          comment_correction: formik.values.comment_correction,
          image: selectedQuestion?.image,
          description_issue: selectedQuestion?.image,
          alternatives_image: selectedQuestion?.alternatives_image,
          alternative_a: formik.values.alternative_a,
          alternative_b: formik.values.alternative_b,
          alternative_c: formik.values.alternative_c,
          alternative_d: formik.values.alternative_d,
          alternative_e: formik.values.alternative_e,
        }

        const question = await api.put('api/updateQuestion', objectSend);
        const idQuestion = question.data.id;

        if (selectedQuestion?.description_issue !== formik.values.description_issue) {
          let image = Object(formik.values.description_issue);
          let formImage = new FormData();
          formImage.append('imageQuestion', image);
          formImage.append('questionID', idQuestion);

          await APIAddImageDescriptionQuestion(formImage);
        }

        if (selectedQuestion?.alternatives_image !== formik.values.alternatives_image) {
          let image = Object(formik.values.alternatives_image);
          let formImage = new FormData();
          formImage.append('imageQuestion', image);
          formImage.append('questionID', idQuestion);

          await APIAddImageAlternativesQuestion(formImage);
        }

        if (selectedQuestion?.image !== formik.values.image) {
          let image = Object(formik.values.image);
          let formImage = new FormData();
          formImage.append('imageQuestion', image);
          formImage.append('questionID', idQuestion);

          await APIAddImageResolutionQuestion(formImage);
        }

        alert('Questão atualizada com sucesso!');

        formik.values.course = "";
        formik.values.matter = "";
        formik.values.bank = "";
        formik.values.institution = "";
        formik.values.year = "";
        // formik.values.office = "";
        // formik.values.modality = "";
        formik.values.difficulty = 0;
        formik.values.weight = 0;
        formik.values.feedback = 0;
        formik.values.comment_correction = "";
        formik.values.image = null;
        formik.values.description_issue = null;
        formik.values.alternatives_image = null;
        formik.values.alternative_a = "";
        formik.values.alternative_b = "";
        formik.values.alternative_c = "";
        formik.values.alternative_d = "";
        formik.values.alternative_e = "";

      } catch (error) {

        console.log(error);

        // if (error.response.status === 422) {
        //   alert('Ops! Alguns campos não foram preechidos da forma correta.');
        // }

      };

      setIsLoading(false);
    }

  });

  async function APIAddImageResolutionQuestion(imageData: any) {

    try {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      await api.post('api/updateImage', imageData, options);
      alert('A imagem pode demorar até 1 minuto para ser cadastrada, então não precisa ficar preocupado caso ela não apareça de imediato. 😄 ');

    } catch (e) {
      console.log(e.response);
      alert('Desculpe. Não foi possível cadastrar a imagem da questão, tente novamente mais tarde...');
    }
  }

  async function APIAddImageDescriptionQuestion(imageData: any) {

    try {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      await api.post('api/updateImageDescription', imageData, options);
      alert('A imagem pode demorar até 1 minuto para ser cadastrada, então não precisa ficar preocupado caso ela não apareça de imediato. 😄 ');

    } catch (e) {
      console.log(e.response);
      alert('Desculpe. Não foi possível cadastrar a imagem da questão, tente novamente mais tarde...');
    }
  }

  async function APIAddImageAlternativesQuestion(imageData: any) {

    try {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      await api.post('api/updateImageAlternatives', imageData, options);
      alert('A imagem pode demorar até 1 minuto para ser cadastrada, então não precisa ficar preocupado caso ela não apareça de imediato. 😄 ');

    } catch (e) {
      console.log(e.response);
      alert('Desculpe. Não foi possível cadastrar a imagem da questão, tente novamente mais tarde...');
    }
  }


  return (
    <>
      <Container>
        <FlexWrapper>
          <p className='title'>Registro de questão</p>
          <BackButton type='button' onClick={() => navigate(ERoutesPath.QUESTOES)}>Voltar</BackButton>
        </FlexWrapper>


        <StyledForm onSubmit={formik.handleSubmit}>
          <ContainerRow >
            {/* style={{ display: displayForm.primary }} */}
            <ContaineForm>
              <Input
                type="name"
                name='course'
                placeholder="Disciplina *"
                onChange={formik.handleChange}
                value={formik.values.course}
                autoCapitalize='words'
                color="gray"
              />

              <Input
                type="name"
                name="matter"
                placeholder="Assunto *"
                onChange={formik.handleChange}
                value={formik.values.matter}
                autoCapitalize='words'
                color="gray"
              />

              <Input
                type="name"
                name="bank"
                placeholder="Banca *"
                onChange={formik.handleChange}
                value={formik.values.bank}
                autoCapitalize='words'
                color="gray"
              />

              <Input
                type="name"
                name="institution"
                placeholder="Instituição *"
                onChange={formik.handleChange}
                value={formik.values.institution}
                autoCapitalize='words'
                color="gray"
              />

              <Input
                type="name"
                name="year"
                placeholder="Ano *"
                onChange={formik.handleChange}
                value={formik.values.year}
                color="gray"
              />
              <Select
                className="difficulty"
                {...formik.getFieldProps("difficulty")}
                options={optionsDifficulty}
                onChange={(e) => {
                  formik.setFieldValue("difficulty", e.target.value);
                }}
                required
              />
              <Select
                className="weight"
                {...formik.getFieldProps("weight")}
                options={optionsWeight}
                onChange={(e) => {
                  formik.setFieldValue("weight", e.target.value);
                }}
                required
              />

            </ContaineForm>
            <ContaineForm style={{ marginTop: -18 }}>
              <Label>Descrição da questão - Imagem</Label>
              <Input
                name="description_issue"
                type="file"
                placeholder="Descrição da questão"
                onChange={(event: any) => {
                  formik.setFieldValue('description_issue', event.currentTarget.files[0])
                }}
                color="gray"
              />

              <Label>Alternativas da questão - Imagem</Label>
              <Input
                name="alternatives_image"
                type="file"
                placeholder="Alternativas"
                onChange={(event: any) => {
                  formik.setFieldValue('alternatives_image', event.currentTarget.files[0])
                }}
                color="gray"
              />

              <Input
                name="alternative_a"
                type="text"
                placeholder="Alternativa A"
                onChange={formik.handleChange}
                value={formik.values.alternative_a}
                color="gray"
              />

              <Input
                name="alternative_b"
                type="text"
                placeholder="Alternativa B"
                onChange={formik.handleChange}
                value={formik.values.alternative_b}
                color="gray"
              />

              <Input
                name="alternative_c"
                type="text"
                placeholder="Alternativa C"
                onChange={formik.handleChange}
                value={formik.values.alternative_c}
                color="gray"
              />

              <Input
                name="alternative_d"
                type="text"
                placeholder="Alternativa D"
                onChange={formik.handleChange}
                value={formik.values.alternative_d}
                color="gray"
              />

              <Input
                name="alternative_e"
                type="text"
                placeholder="Alternativa E"
                onChange={formik.handleChange}
                value={formik.values.alternative_e}
                color="gray"
              />

              <Select
                className="feedback"
                {...formik.getFieldProps("feedback")}
                options={optionsCorrectQuestion}
                onChange={(e) => {
                  console.log(e.target.title);
                  formik.setFieldValue("feedback", e.target.value);
                }}
                required
              />

              <Input
                name="comment_correction"
                type="name"
                placeholder="Explicação da opção correta *"
                onChange={formik.handleChange}
                value={formik.values.comment_correction}
                color="gray"
              />

              <Label>Correção da questão - Imagem</Label>
              <Input
                name="image"
                type="file"
                placeholder="Imagem da resoluação"
                onChange={(event: any) => {
                  formik.setFieldValue('image', event.currentTarget.files[0])
                }}
                color="gray"
              />

            </ContaineForm>
          </ContainerRow>

          {isLoading ? (
            <div
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <ContainerButton>
                <Button type="button" onClick={() => { handleUpdateQuestion() }}>
                  Atualizar questão
                </Button>
              </ContainerButton>
            </>
          )}

        </StyledForm>
      </Container>
    </>
  );
}