import { SelectHTMLAttributes } from "react";
import { ContainerSelect, Option } from "./styles";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: { value: number; label: string; selected?: boolean }[];
  name: string;
  placeholder?: string;
}

/**
 * @deprecated use from 'components' instead
 */
const Select = ({ options, name, placeholder, ...rest }: SelectProps) => {
  return (
    <ContainerSelect className="Select" name={name} {...rest}>
      {placeholder && (
        <Option value="" disabled selected>
          {placeholder}
        </Option>
      )}

      {options.map(({ value, label, selected }) => (
        <Option value={value} selected={!placeholder && selected}>
          {label}
        </Option>
      ))}
    </ContainerSelect>
  );
};

export default Select;
