import styled from "styled-components";
import backgroundImg from "../../assets/background/pageImg.png";

export const Background = styled.div`
  background: #3a66fb;
`;

export const BannerHomeContainer = styled.div`
  padding: 80px 30px;

  background-image: url(${backgroundImg});
  background-size: auto;
  background-position: bottom right;
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    background: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 30px;
  margin: auto;
  max-width: 460px;

  position: relative;

  border-right: 10px ridge #ffffff;
  border-bottom: 10px solid #3a66fb;
  border-left: 10px groove #ffffff;
`;

export const Title = styled.p`
  font-size: 36px;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  @media (max-width: 800px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: #ffffff;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 300;
  text-align: justify;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
`;

export const LogoContainer = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
`;
