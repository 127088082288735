import { PLAN_VALUES } from './helper'
import * as S from './styles'

export const PlanValues = ({ isModal = false }) => {

  const openLink = (link: string) => {
    window.open(link)
  }

  return (
    <div id="plan">
      <S.Container id='planos' isModal={isModal}>
        {!isModal && (
          <S.Title isBigger>Planos</S.Title>
        )}


        <S.CardsContainer>
          {PLAN_VALUES.map((plan) =>
            <S.PlanContainer key={plan.title}>
              <S.Title>{plan.title}</S.Title>

              {plan.itens.map((item) =>
                <li key={item}>{item}</li>
              )}

              <S.Title isBigger>{plan.price}</S.Title>

              <S.ButtonContainer color="whiteBlack" onClick={() => openLink(plan.link)}>
                ASSINE AGORA
              </S.ButtonContainer>
            </S.PlanContainer>
          )}
        </S.CardsContainer >
      </S.Container>
    </div>
  )
}