import { ButtonHTMLAttributes } from "react";
import { ButtonContainer } from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;

  isSecondary?: boolean;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean
}


/**
 * @deprecated use from 'components' instead
 */
const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    isSecondary = false,
    size = "medium",
    fullWidth = false,
    ...rest
  } = props;

  return (
    <ButtonContainer
      className="Button"
      onClick={onClick}
      isSecondary={isSecondary}
      size={size}
      {...rest}
      style={fullWidth ? { width: '100%' } : undefined}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
