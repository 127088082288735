export const END_POINTS = {
  filterQuestions: "api/filtersQuestions",
  flashcards: "api/flashcards",

  lastQuestionsAnswered: "api/lastQuestionsAnswered",
  responseQuestion: "api/responseQuestion",
  questionByFilter: "api/questionsByFilter",
  coursesByQuestion: "api/coursesByQuestion",
  /**
   * @deprecated use from studiesSubjectsByUser instead
   */
  studiesListByUser: "api/studied/list/student",
  studiesSubjectsByUser: "/api/studied/subjects",

  addStudiedSubject: "api/studied/add/subjects",
  removeStudiedSubject: "api/studied/delete/subjects",

  login: "login",
  logout: "logout",

  userAccount: "api/user/account"
};
