import styled from 'styled-components';

export const Container = styled.div`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  padding: 100px 64px;

  .title {
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  .subtitle {
    margin-bottom: 36px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  .Button {
    width: 300px;
    height: 48px;
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;

export const ContainerRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContaineForm = styled.div`
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  label{
    width: 100%;
    text-align: left;
    padding-left: 4px;
    padding-bottom: 4px;
    font-family: Poppins;
    color: #78909D;
    font-size: 14px;
  }
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;


export const ContainerButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const TextArea = styled.textarea`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 20px;
  background: ${(props) => props.color === 'white' ?'#FFF':'#ECF0F2'};
  border-radius: 8px;
  border-style: none;
  font-family: inherit;
  &::placeholder {
    font-size: 12px;
    line-height: 16px;
    color: #78909D;
  }
`;

export const Label = styled.p`
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 4px;
    margin-left: 4px;
`;
