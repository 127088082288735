import { useContext, useState } from "react";
import { Link } from "react-scroll";

import { useNavigate, useLocation } from "react-router-dom-v5-compat";

import logoReviseRadio from "../../../assets/logoReviseRadio.png";
import { AuthContext } from "../../../contexts/auth";

import {
  Typography,
  Button,
  LoginModal,
  RegisterModal,
  PaymentModal,
  Sidebar,
} from "../../../components";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { ERoutesPath } from "../../../routes";

import * as S from "./styles";

enum EModalType {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  PAYMENT = "PAYMENT",
}

export const Header = () => {
  const { isUserLogged, user, typeUser, signOut, isPaidActive } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [modalActive, setModalActive] = useState<EModalType | undefined>(
    undefined
  );
  const [activeTutorial, setActiveTutorial] = useState<boolean>(false);

  const handleModal = (modalType: EModalType) => {
    setModalActive(modalType);
  };

  const handleSignOut = () => {
    signOut()
    navigate(ERoutesPath.ROOT)
  };

  const handleTutorial = () => {
    setActiveTutorial(true);
    setTimeout(() => {
      setActiveTutorial(false);
    }, 100);
  };

  const ShowTutorial = () => (
    <>
      {isUserLogged && isPaidActive && (
        <TipsAndUpdatesIcon
          fontSize="medium"
          style={{ display: "flex", color: "#3a66fb", cursor: "pointer" }}
          onClick={handleTutorial}
        />
      )}
    </>
  );

  return (
    <>
      <S.Container>
        <Sidebar triggerTutorial={activeTutorial} />
        <S.ContentLogo>
          <img src={logoReviseRadio} alt="logo" height={44} />
        </S.ContentLogo>
        <S.TutorialWrapper>
          <ShowTutorial />
        </S.TutorialWrapper>

        <S.ContentListButtons>
          <ShowTutorial />
          <Link
            activeClass="active"
            to=""
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={() => {
              scroll(0, 0);
              navigate(ERoutesPath.ROOT);
            }}
          >
            <Typography>Início</Typography>
          </Link>

          {isUserLogged && (
            <Link
              activeClass="active"
              to=""
              spy={true}
              smooth={true}
              offset={-120}
              duration={500}
              onClick={() => {
                navigate(ERoutesPath.QUESTOES);
              }}
            >
              <Typography>Filtrar questões</Typography>
            </Link>
          )}

          {!isUserLogged ? (
            <>
              <Link
                activeClass="active"
                to="planos"
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
                onClick={() => {
                  if (location.pathname !== "/")
                    handleModal(EModalType.PAYMENT);
                }}
              >
                <Typography>Assinar plano</Typography>
              </Link>

              <Link
                activeClass="active"
                to=""
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
                onClick={() => handleModal(EModalType.LOGIN)}
              >
                <Typography>Entrar</Typography>
              </Link>

              <Button
                isSecondary={false}
                onClick={() => handleModal(EModalType.REGISTER)}
              >
                CADASTRE-SE
              </Button>
            </>
          ) : (
            <>
              {typeUser === "10" && (
                <Link
                  activeClass="active"
                  to=""
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  onClick={() => {
                    navigate(ERoutesPath.INICIO_PROFISSIONAL);
                  }}
                >
                  <Typography>Painel Administrativo</Typography>
                </Link>
              )}

              <S.ProfileTextContainer>
                <Typography>{user.name}</Typography>
                <S.SignOutButton onClick={handleSignOut}>Sair</S.SignOutButton>
              </S.ProfileTextContainer>
            </>
          )}
        </S.ContentListButtons>
      </S.Container>

      {modalActive && (
        <>
          {modalActive === EModalType.LOGIN && (
            <LoginModal modalDismiss={() => setModalActive(undefined)} />
          )}

          {modalActive === EModalType.REGISTER && (
            <RegisterModal modalDismiss={() => setModalActive(undefined)} />
          )}

          {modalActive === EModalType.PAYMENT && (
            <PaymentModal modalDismiss={() => setModalActive(undefined)} />
          )}
        </>
      )}
    </>
  );
};
