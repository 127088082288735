import styled from "styled-components";

export const Container = styled.div`
  z-index: 200;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 551px;
  display: flex;
  flex-direction: column;
  padding: 24px 80px 40px 80px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(26, 26, 26, 0.2);
  border-radius: 8px;
  text-align: center;

  @media (max-width: 800px) {
    width: 95%;
    padding: 24px 16px;
    height: 65%;
    top: 40%;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }
`;

export const Background = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #00000035;
`;
