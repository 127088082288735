import { Container, Subtitle, Title } from './styles';

interface FeatureCardProps {
  title: string;
  subtitle: string;
  imageSource: string;
  onClick: () => void;
}

const FeatureCard = ({ title, subtitle, imageSource, onClick }: FeatureCardProps) => {
  return (
    <Container onClick={() => onClick()}>
      <Title>{title}</Title>
      <img src={imageSource} alt={imageSource} style={{ width: 80, height: 80 }} />
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};

export default FeatureCard;
