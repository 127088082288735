import { Typography } from '../../../components'

import { TPageTemplateProps } from './types'
import * as S from './styles'

export const PageTemplate = ({title, subTitle, children}: TPageTemplateProps) => (
  <S.Container>
    <S.Header>
      <Typography variant='title'>{title}</Typography>
      {subTitle && <Typography>{subTitle}</Typography>}
    </S.Header>

    {children}
  </S.Container> 
)
