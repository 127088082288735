export enum ERoutesPath {
  ROOT = "/",
  INICIO_PROFISSIONAL = "/inicio-profissional",
  ADMINISTRAR_QUESTOES = "/administrar/questoes",

  QUESTOES = "/questoes",
  ASSUNTOS_ESTUDADOS = "/assuntos-estudados",
  ATUALIZAR_QUESTOES = "/atualizar-questao",
  USUARIOS = "/usuarios",
  REVISOES = "/revisoes",
  REVISAO = "/revisao",
  REVISAO_GERAL = "/revisao-geral",
  REVISE_RAPIDO = "/revise-rapido",
  CORRECAO = "/correcao",
}
