import styled from "styled-components";
import Button from "../../components/Button";

export const Container = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;

  width: 100%;
  height: 100%;
  padding: ${({ isModal }) => (isModal ? "undefined" : "40px 60px")};

  background: #ffffff;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.h2<{ isBigger?: boolean }>`
  font-size: ${({ isBigger }) => (isBigger ? "40px" : "24px")};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  min-height: 56px;
  color: #3a66fb;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;

  @media (max-width: 800px) {
    font-size: ${({ isBigger }) => (isBigger ? "32px" : "24px")};
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;

  border: 2px solid #000000;

  max-width: 330px;
`;

export const ButtonContainer = styled(Button)`
  margin: auto;
`;
