import styled from "styled-components";

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubjectItem = styled.div`
  background-color: #ecf0f2;
  display: flex;
  flex-direction: column;

  border-radius: 8px;
`;

export const Item = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const MattersContainer = styled.div`
  padding: 16px 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }
`;

export const SelectAllContainer = styled.div`
  padding: 0 24px;
`;
