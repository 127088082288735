import { Typography } from '../../../components';
import { TModalContainerProps } from './types';

import * as S from './styles';

export const ModalContainer = ({ children, title, subtitle, modalDismiss }: TModalContainerProps) => (
  <S.Background onClick={() => modalDismiss()}>
    <S.Container onClick={(e) => e.stopPropagation()}>
      <Typography variant='subTitle' align='center'>{title}</Typography>
      {subtitle && <Typography color='#78909d' align='center'>{subtitle}</Typography>}

      {children}
    </S.Container>
  </S.Background>
);

