import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  EAvailableFields,
  TFilterFormValues,
} from "../../components/organisms/QuestionFilter/types";
import ContentCutIcon from "@mui/icons-material/ContentCut";

import api, { END_POINTS } from "../../services/api";
import ReportQuestion from "../../components/modal/ReportQuestion";
import { AuthContext } from "../../contexts/auth";
import { TAnsweredQuestionResponse, TLocalAnswered } from "./type";
import { TQuestion } from "../../contexts/question";
import { ERoutesPath } from "../../routes";
import Pagination from "../../components/Pagination";
import {
  EditQuestion,
  PageTemplate,
  QuestionFilter,
  ImageRightWrong,
  Button,
} from "../../components";

import { CorrectionFeedback, OptionButton, QuestionImage } from "./helpers";

import * as S from "./styles";

export const Questions: React.FC = () => {
  const FILTER_OPTIONS = [
    EAvailableFields.COURSES,
    EAvailableFields.MATTERS,
    EAvailableFields.BANKS,
    EAvailableFields.YEARS,
    EAvailableFields.DIFFICULTY,
    EAvailableFields.TIME,
  ];

  const navigate = useNavigate();
  const { isUserLogged, isFeedbackQuestion, isPaidActive, user } =
    useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isReportQuestion, setIsReportQuestion] = useState(false);
  const [currentQuestionIssue, setCurrentQuestionIssue] = useState("");
  const [offset, setOffset] = useState(0);

  const [questions, setQuestions] = useState<Array<TQuestion>>();
  const [questionsToShow, setQuestionsToShow] = useState<Array<TQuestion>>();

  const [errorQuestion, setErrorQuestion] = useState<number>();
  const [correctQuestion, setCorrectQuestion] = useState<number>();
  const [optionSelected, setOptionSelected] = useState("");
  const [answeredQuestions, setAnsweredQuestions] = useState<TLocalAnswered>();

  const idAccount = user.id_user;
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    if (isPaidActive) {
      handleGetAnsweredQuestions();
    } else {
      navigate(ERoutesPath.ROOT);
    }
  }, []);

  useEffect(() => {
    setQuestionsToShow(
      questions && questions.slice(offset, offset + ITEMS_PER_PAGE)
    );
  }, [questions, offset]);

  const handleGetAnsweredQuestions = async () => {
    try {
      setAnsweredQuestions(undefined);
      const listQuestions = await api.get(
        `${END_POINTS.lastQuestionsAnswered}?idAccount=${idAccount}`
      );
      let answeredObj: TLocalAnswered;
      answeredObj = listQuestions.data.reduce(
        (acc: TLocalAnswered, curr: TAnsweredQuestionResponse) => ({
          ...acc,
          [curr.id_question]: curr.correct,
        }),
        {}
      );
      setAnsweredQuestions(answeredObj);
    } catch {
      console.error("Erro ao buscar questões respondidas");
    }
  };

  const handleResponseQuestion = async (questionID: number, option: string) => {
    if (isUserLogged) {
      setErrorQuestion(undefined);
      setCorrectQuestion(undefined);
      try {
        const body = {
          id_question: questionID,
          id_account: idAccount,
          feedback: option.toUpperCase(),
        };

        await api.post(END_POINTS.responseQuestion, body);
        setCorrectQuestion(questionID);
        setAnsweredQuestions((prevValues) => ({
          ...prevValues,
          [questionID]: true,
        }));
      } catch (err) {
        setErrorQuestion(questionID);
        setAnsweredQuestions((prevValues) => ({
          ...prevValues,
          [questionID]: false,
        }));
      }
    } else {
      alert(
        "Desculpe. É necessário realizar o seu cadastro na revise para responder as questões."
      );
    }
  };

  const handleReport = (questionID: number) => {
    setCurrentQuestionIssue(String(questionID));
    setIsReportQuestion(true);
  };

  const verifyAnsweredQuestions = (id: number) =>
    answeredQuestions && answeredQuestions[id];

  const handleFilter = async (data: TFilterFormValues) => {
    try {
      setQuestions(undefined);
      setIsLoading(true);
      const filteredQuestions = await api.post<Array<TQuestion>>(
        END_POINTS.questionByFilter,
        data
      );
      setQuestions(filteredQuestions.data);
    } catch (error) {
      alert(
        "Desculpe. Não foi possível carregar as questões, tente novamente mais tarde..."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAlternatives = (question: any) => {
    return ["a", "b", "c", "d", "e"].map((letter) => ({
      key: question.id + letter,
      value: question[`alternative_${letter}`],
      label: letter.toUpperCase(),
    }));
  };

  return (
    <PageTemplate title="Filtrar Questões">
      <QuestionFilter
        fieldToShow={FILTER_OPTIONS}
        onSubmit={handleFilter}
        showNotFound={questions && questions.length === 0}
        isLoadingFilter={isLoading}
      />

      {questions && questions.length > 0 && (
        <S.Container>
          {questionsToShow?.map((question) => (
            <S.Row
              key={question.id}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <ImageRightWrong
                isCorrect={verifyAnsweredQuestions(question.id)}
              />

              <S.QuestionWrapper>
                <EditQuestion question={question} />
                <QuestionImage src={question.description_issue} />
                <QuestionImage src={question.alternatives_image} />

                <S.AlternativesImageContainer>
                  <div style={{ width: "100%" }}>
                    {getAlternatives(question).map(({ key, value, label }) => (
                      <OptionButton
                        key={key}
                        option={value || label}
                        questionId={question.id}
                        isSelected={optionSelected === key}
                        onSelect={() => setOptionSelected(key)}
                      />
                    ))}
                  </div>
                </S.AlternativesImageContainer>

                {optionSelected.slice(0, -1) === question.id.toString() && (
                  <Button
                    size="small"
                    onClick={() =>
                      handleResponseQuestion(
                        question.id,
                        optionSelected.slice(-1)
                      )
                    }
                    bgColor="#00B8C0"
                    txtColor="#000"
                  >
                    Confirmar
                  </Button>
                )}

                {errorQuestion === question.id && (
                  <CorrectionFeedback
                    question={question}
                    isFeedbackQuestion={isFeedbackQuestion}
                    handleReport={handleReport}
                  />
                )}

                {correctQuestion === question.id && (
                  <CorrectionFeedback
                    question={question}
                    isFeedbackQuestion={isFeedbackQuestion}
                    isCorrect
                    handleReport={handleReport}
                  />
                )}
              </S.QuestionWrapper>
            </S.Row>
          ))}

          <Pagination
            itemsPerPage={ITEMS_PER_PAGE}
            totalItems={questions.length}
            offset={offset}
            setOffset={setOffset}
          />
        </S.Container>
      )}

      {isReportQuestion && (
        <ReportQuestion
          modalDismiss={() => {
            setIsReportQuestion(false);
          }}
          idQuestion={currentQuestionIssue}
          idAccount={idAccount}
        />
      )}
    </PageTemplate>
  );
};
